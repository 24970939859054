import React, { Component } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Search, SidebarMain } from "./style";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  CircularProgress,
  Collapse,
  Input,
  List,
  ListItemButton,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import NewCloseLogo from "../../assets/images/NewCloseLogo.png";
import CloudkidaLogo from "../../assets/images/cloudkida-logo.png";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import ChevronRight from "@mui/icons-material/ChevronRight";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GroupsIcon from "@mui/icons-material/Groups";
import PolicyIcon from "@mui/icons-material/Policy";
import CategoryIcon from "@mui/icons-material/Category";
import BarChartIcon from "@mui/icons-material/BarChart";
import HistoryIcon from "@mui/icons-material/History";
import { Grid, InputAdornment } from "@mui/material";
import Cookies from "js-cookie";
import AccountMenu from "../AccountMenu/AccountMenu";
import { withStyles } from "@mui/styles";
import axios from "axios";
import { configuration } from "../../helper/appconfig";
import { connect } from "react-redux";
import GroupIcon from "@mui/icons-material/Group";
import { handleAuthTokenError } from "../../redux/actions/authActions";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SearchIcon from "@mui/icons-material/Search";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { DarkButton } from "../../common-styled";
import CompanyLogo from "../../pages/CompanyLogo/CompanyLogo";
import LaptopWindowsIcon from "@mui/icons-material/LaptopWindows";
import BadgeIcon from '@mui/icons-material/Badge';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  [theme.breakpoints.down("md")]: {
    display: "block",
    position: "fixed",
    zIndex: "99999999",
    width: "40vw",
  },
  [theme.breakpoints.down("sm")]: {
    width: "80vw",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("md")]: {
        width: "100% ",
      },
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
    : {
      width: `calc(100% - ${theme.spacing(8)} + 1px)`,
      [theme.breakpoints.down("md")]: {
        width: "100% ",
      },
    }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export class NewSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dropDown: false,
      dropDown_two: false,
      dropDown_three: false,
      dropDown_four: false,
      dropDown_five: false,
      dropDown_six: false,
      dropDown_seven: false,
      dropDown_eight: false,
      dropDown_nine: false,
      dropDown_ten: false,
      dropDown_eleven: false,
      dropDown_eleven: false,
      dropDown_twelve: false,
      labData: [],
      groupData: [],
      searchString: "",
      error: [],
      loading: false,
      isSearchLab: false,
    };
  }

  getAllLabs = () => {
    const url = configuration.getLabs;
    const tokenType = this?.props?.authData?.tokenType;
    const idToken = this?.props?.authData?.idToken;
    const headers = {
      Authorization: `${tokenType} ${idToken}`,
    };
    return axios
      .get(url, { headers })
      .then((response) => {
        console.log(response?.data, "AllLabsData");
        this.setState({
          labData: response.data?.labs,
        });
      })
      .catch((error) => {
        console.log("Header in catch block -->", error.headers);
        console.log(error);
        let errorMessage = error?.response?.data?.message;
        if (errorMessage === "Unauthorized") {
          this?.props?.handleAuthTokenError(this.props);
        }
      });
  };

  getAllGroups = () => {
    const url = configuration.getAllGroup;
    const tokenType = this.props?.authData?.tokenType;
    const idToken = this.props?.authData?.idToken;
    const headers = {
      Authorization: `${tokenType} ${idToken}`,
    };
    return axios
      .get(url, { headers })
      .then((response) => {
        console.log(response.data, "groupData");
        this.setState({
          groupData: response?.data?.groups,
        });
      })
      .catch((error) => {
        console.log(error, "errorFromGetAllGroups");
        let errorMessage = error?.response?.data?.message;
        if (errorMessage === "Unauthorized") {
          this?.props?.handleAuthTokenError(this.props);
        }
      });
  };

  classifyLabs = () => {
    let groupLabs = [];
    const { groupData, labData } = this.state;

    groupData?.length > 0 &&
      labData?.length > 0 &&
      groupData?.forEach((group) => {
        let temp = group.labs || [];
        labData?.forEach((lab) => {
          if (group?.groupId === lab?.labGroup) {
            temp.push(lab);
          }
        });
        groupLabs.push({ ...group, labs: temp });
      });
    this.setState({
      groupData: groupLabs,
    });
  };

  componentDidMount() {
    const { authData } = this.props;
    let userRole = authData?.userRole;
    if (userRole === "TenantUser") {
      let promises = [];

      promises.push(this.getAllLabs());
      promises.push(this.getAllGroups());

      Promise.all(promises)
        .then((res) => {
          console.log("set the loader to false");
          userRole === "TenantUser" && this.classifyLabs();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }

    // if (this?.props?.location?.pathname.includes("/labGroup/")) {
    //     this.setState({isSearchLab:true})
    // }
    // // else{
    // //     this.setState({isSearchLab:false})
    // // }
  }

  handleDrawerOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleSearchTextChange = (evt) => {
    console.log(evt?.target?.value, "searchText");
    this.setState({
      searchString: evt?.target?.value,
      error: [],
    });
  };

  handleSearch = () => {
    const { searchString } = this.state;
    let error = [];
    if (!searchString.trim()) {
      error.push("searchStringEmpty");
    }
    if (error?.length) {
      this.setState({
        error,
      });
    } else {
      this.setState(
        {
          error: [],
          loading: true,
        },
        () => this?.searchLabs()
      );
    }
  };
  handleOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.handleSearch();
    }
  };
  searchLabs = () => {
    const url = configuration.searchLabs;
    const { authData, handleAuthTokenError, history } = this.props;
    const tokenType = authData?.tokenType;
    const idToken = authData?.idToken;
    const headers = {
      Authorization: `${tokenType} ${idToken}`,
    };
    const body = {
      search: this?.state?.searchString,
    };
    axios
      .post(url, body, { headers })
      .then((response) => {
        console.log(response.data, "searchLabData");
        this.setState({ loading: false });
        if (response?.data?.length) {
          history.push({
            pathname: "/filteredLabs",
            state: { filteredLabData: response.data },
          });
        } else {
          toastAlert("No labs found with this title", "success");
        }
      })
      .catch((error) => {
        console.log(error, "errorFromSearchLabs");
        this.setState({ loading: false });
        let errorMessage = error?.response?.data?.message;
        if (errorMessage === "Unauthorized") {
          handleAuthTokenError(this.props);
        }
        toastAlert(
          errorMessage
            ? errorMessage
            : "Something went wrong. Please try again later.",
          "error"
        );
      });
  };

  getAppBarTitle = () => {
    let pathName = this?.props?.location?.pathname;
    if (pathName === "/dashboard") {
      return "Dashboard";
    } else if (pathName === "/userProfile") {
      return "User Profile";
    } else if (pathName === "/AllUser") {
      return "All Users";
    } else if (pathName === "/createuser") {
      return "Create User";
    } else if (pathName === "/createlab") {
      return "Create Lab";
    } else if (pathName === "/alllabs") {
      return "All Labs List";
    } else if (pathName === "/filteredLabs") {
      return "Filtered Labs";
    } else if (pathName === "/allPolicies") {
      return "All Policy List";
    } else if (pathName === "/allAttachedPolicies") {
      return "All Attached Policy List";
    } else if (pathName === "/createcategory") {
      return "Create Category";
    } else if (pathName === "/category/AllCategory") {
      return "All Category";
    } else if (pathName === "/level/AllLevels") {
      return "All Level";
    } else if (pathName === "/allUserGroups") {
      return "All User Groups";
    } else if (pathName === "/level/CreateLevels") {
      return "Create Level";
    } else if (pathName === "/allGroups") {
      return "All Group";
    } else if (pathName === "/attachPolicy") {
      return "Attach Policy";
    } else if (pathName === "/creategroup") {
      return "Create Group";
    } else if (pathName === "/createpolicy") {
      return "Create Policy";
    } else if (pathName === "/createUserGroup") {
      return "Create User Group";
    } else if (pathName === "/myCredit") {
      return "My Credits";
    } else if (pathName === "/Transactions") {
      return "Transactions";
    } else if (pathName === "/Payment") {
      return "Payment";
    } else if (pathName === "/labProvisionHistory") {
      return "Lab Provision History";
    } else if (pathName.includes("/viewlab/")) {
      return "View Lab";
    } else if (pathName.includes("/editlab/")) {
      return "Edit Lab";
    } else if (pathName.includes("/editcategory/")) {
      return "Edit Category";
    } else if (pathName.includes("/editlevel/")) {
      return "Edit Level";
    } else if (pathName.includes("/editGroup/")) {
      return "Edit Group";
    } else if (pathName.includes("/editUserGroup/")) {
      return "Edit User Group";
    } else if (pathName.includes("/editAttachedPolicy/")) {
      return "Edit Attached Policy";
    } else if (pathName.includes("/viewPolicy/")) {
      return "View Policy";
    } else if (pathName.includes("/editpolicy/")) {
      return "Edit Policy";
    } else if (pathName.includes("/groupUsers/")) {
      return "Group Users";
    } else if (pathName.includes("/monitoring/live-user-list")) {
      return "Live User List";
    } else if (pathName.includes("/view-ratings/")) {
      return "View Rating";
    } else if (pathName.includes("/support")) {
      return "Support";
    } else if (pathName.includes("/generateCoupon")) {
      return "Generate Coupon";
    } else if (pathName.includes("/couponList") && pathName.startsWith("/couponList/")) {
      return "All Users of Coupon";
    } else if (pathName.includes("/couponList")) {
      return "All Coupons";
    } else if (pathName.includes("/myGroups")) {
      return "My Groups"
    } else if (pathName.includes("/myAttachedPolicies")) {
      return "My Attached Policies"
    } else if (pathName.includes("/alllabs/")) {
      return this?.props?.location?.state?.labGroup;
    } else if (pathName.includes("/labGroup/")) {
      return this?.props?.location?.state?.labGroup;
    } else if (pathName.includes("/groupPolicies/")) {
      return this?.props?.location?.state?.groupName;
    }
  };

  getSearchLab = () => {
    let pathName = this?.props?.location?.pathname;
    if (pathName.includes("/labGroup/")) {
      return true;
    }
    if (pathName.includes("/viewlab/")) {
      return true;
    }
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
      dropDown: false,
      dropDown_two: false,
      dropDown_three: false,
      dropDown_four: false,
      dropDown_five: false,
      dropDown_six: false,
      dropDown_seven: false,
      dropDown_eight: false,
      dropDown_nine: false,
      dropDown_ten: false,
      dropDown_eleven: false,
      dropDown_twelve: false,
    });
  };

  render() {
    const {
      dropDown,
      dropDown_two,
      dropDown_three,
      dropDown_four,
      dropDown_five,
      dropDown_six,
      dropDown_seven,
      dropDown_eight,
      dropDown_nine,
      dropDown_ten,
      dropDown_eleven,
      dropDown_twelve,
      open,
      groupData,
      error,
      loading,
    } = this.state;
    const { authData, location } = this.props;
    return (
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <SidebarMain>
            <Box sx={{ display: "flex" }} className="demo_class">
              <CssBaseline />
              {/* Appbar Content */}
              <AppBar className="appbarmain" position="fixed" open={open}>
                <Toolbar style={{ alignItems: "center" }}>
                  <Grid container style={{ alignItems: "center" }}>
                    <Grid item lg={6} sm={3} md={5} xs={5}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          onClick={
                            open
                              ? () => this.handleDrawerClose()
                              : () => this.handleDrawerOpen()
                          }
                          edge="start"
                          className="menuIcon"
                        >
                          {open ? (
                            <ChevronLeftIcon />
                          ) : (
                            <MenuIcon className="menu" />
                          )}
                        </IconButton>
                        <Typography
                          className="appbarTitle"
                          variant="h6"
                          noWrap
                          component="div"
                        >
                          {this?.getAppBarTitle()}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={9}
                      md={7}
                      xs={7}
                      className="app_bar_right_content"
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {(location?.pathname === "/dashboard" ||
                          location?.pathname === "/filteredLabs" ||
                          this?.getSearchLab() === true) && (
                            <>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    marginTop: error?.length && "12px",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <Input
                                      style={{
                                        borderRadius: "5px 0px 0px 5px",
                                        padding: "2.3px 5px",
                                        background: "white",
                                        border: "1px solid black",
                                      }}
                                      sx={{
                                        display: {
                                          xs: "block  !important ",
                                          sm: "block !important",
                                          md: "block !important",
                                          lg: "block !important",
                                        },
                                      }}
                                      className="search"
                                      onChange={(e) =>
                                        this.handleSearchTextChange(e)
                                      }
                                      fullWidth
                                      // endAdornment={
                                      //     <InputAdornment position="end">
                                      //         <SearchIcon onClick={() => this.handleSearch()}/>
                                      //     </InputAdornment>
                                      // }
                                      placeholder="Quick Search for Labs..."
                                      size="small"
                                      type="search"
                                      onKeyDown={this.handleOnKeyDown}
                                    />
                                  </div>
                                  <div>
                                    <DarkButton
                                      style={{
                                        borderRadius: "0px 5px 5px 0px",
                                        minWidth: "45px",
                                      }}
                                      onClick={() => this.handleSearch()}
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          sx={{ color: "#fff" }}
                                          size={20}
                                        />
                                      ) : (
                                        <SearchIcon />
                                      )}
                                    </DarkButton>
                                  </div>
                                </div>
                                <div>
                                  {error.includes("searchStringEmpty") && (
                                    <span style={{ color: "red" }}>
                                      kindly enter lab value!
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                        <div
                          sx={{ display: { xs: "unset  !important" } }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                          }}
                        >
                          <div>
                            <Typography
                              sx={{
                                display: {
                                  xs: "none !important ",
                                  sm: "block !important",
                                  md: "block!important",
                                  lg: "block !important",
                                },
                              }}
                              className="admin-title"
                              variant="h6"
                            >
                              {(authData?.firstName ||
                                Cookies.get("firstName")) +
                                " " +
                                (authData?.lastName || Cookies.get("lastName"))}
                            </Typography>
                            <Typography
                              sx={{
                                display: {
                                  xs: "none !important ",
                                  sm: "block !important",
                                  md: "block !important",
                                  lg: "block !important",
                                },
                              }}
                              variant="p"
                              className="small-text"
                            >
                              {authData?.userEmail || Cookies.get("email")}
                            </Typography>
                          </div>
                          <AccountMenu />
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none !important",
                          lg: "none !important",
                        },
                      }}
                      item
                      lg={6}
                      sm={8}
                      md={6}
                      xs={12}
                      className="app_bar_right_content"
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <Input
                            className="search"
                            fullWidth
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //         <SearchIcon style={{ color : "red"}}/>
                            //     </InputAdornment>
                            // }
                            placeholder="Quick Search For Labs..."
                            size="small"
                            type="search"
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              {/* Appbar Content */}
              <Drawer
                className="drawerMain"
                variant="permanent"
                open={open}
                onClick={open ? () => "" : () => this.handleDrawerOpen()}
              >
                <DrawerHeader className="drawerheader">
                  {open ? (
                    <CompanyLogo />
                  ) : (
                    <img
                      src={this.props.authData.logo}
                      alt="logo"
                      className="logo_close logo-sidebar"
                    />
                  )}
                  <IconButton
                    sx={{
                      display: {
                        xs: "block ",
                        sm: "block",
                        md: "none !important",
                        lg: "none !important",
                      },
                    }}
                    onClick={() => this.handleDrawerClose()}
                  >
                    {/* {theme.direction === 'rtl' ?  */}
                    <HighlightOffIcon />
                    {/* : 
                                    <HighlightOffIcon style={{fontSize:"40px"}} />
                                    } */}
                  </IconButton>
                </DrawerHeader>
                {/* dashnoard::Start */}
                <ListItem className="sidebar-list-items">
                  <ListItemIcon>
                    <HomeIcon />
                  </ListItemIcon>
                  <Link
                    to={{
                      pathname: "/dashboard",
                    }}
                  >
                    <ListItemText primary="Dashboard" />
                  </Link>
                </ListItem>
                {/* } */}
                {/* dashboard::End */}
                {/* User::Start */}
                {Cookies.get("userRole") === "TenantAdmin" && (
                  <>
                    <ListItem
                      className="sidebar-list-items"
                      onClick={() =>
                        open &&
                        this.setState({
                          dropDown: !dropDown,
                          dropDown_two: false,
                          dropDown_three: false,
                          dropDown_four: false,
                          dropDown_five: false,
                          dropDown_six: false,
                          dropDown_seven: false,
                          dropDown_eight: false,
                          dropDown_nine: false,
                          dropDown_ten: false,
                          dropDown_eleven: false,
                          dropDown_twelve: false,
                        })
                      }
                    >
                      <ListItemIcon>
                        <AccountCircleOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="User" />
                      {dropDown ? <ExpandMoreIcon /> : <ChevronRight />}
                    </ListItem>
                    <Collapse in={dropDown} timeout="auto" unmountOnExit>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/createmultipleusers">
                          <ListItemText
                            primary="Create Multiple Users"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/createuser">
                          <ListItemText
                            primary="Create User"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/AllUser">
                          <ListItemText
                            primary="All Users"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                    </Collapse>
                  </>
                )}
                {/* User::End */}
                {/* Lab::Start */}
                <>
                  {/* <ListItem className='sidebar-list-items'
                                        onClick={() => open && 
                                            this.setState({
                                                dropDown : false,
                                                dropDown_two : !dropDown_two,
                                                dropDown_three : false,
                                                dropDown_four : false,
                                                dropDown_five : false,
                                                dropDown_six : false,
                                                dropDown_seven : false,
                                                dropDown_eight : false,
                                                dropDown_nine : false,
                                            })
                                        }
                                    >
                                        <ListItemIcon>
                                            <DesktopWindowsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Lab" />
                                        {dropDown_two ? 
                                            <ExpandMoreIcon />
                                            : 
                                            <ChevronRightIcon />
                                        }
                                    </ListItem> */}
                  {/* <Collapse in={dropDown_two} timeout="auto" unmountOnExit>
                                        {Cookies.get("userRole") === "TenantAdmin" &&
                                            <>
                                                <ListItem >
                                                    <ListItemIcon> */}
                  {/* 
                                                        <StarBorder />
                                                        */}
                  {/* </ListItemIcon>
                                                    <Link to="/createlab">
                                                        <ListItemText primary="Create Lab" className="Link_item" />
                                                    </Link>
                                                </ListItem>
                                                <ListItem >
                                                    <ListItemIcon> */}
                  {/* 
                                                        <StarBorder />
                                                    */}
                  {/* </ListItemIcon>
                                                    <Link 
                                                        to={{
                                                            pathname: "/alllabs",
                                                            state : {
                                                                labGroup : "All"
                                                            },
                                                        }}
                                                    >
                                                        <ListItemText primary="All Labs" className="Link_item" />
                                                    </Link>
                                                </ListItem>
                                            </>
                                        } */}
                  {/* {Cookies.get("userRole") === "TenantUser" &&
                                            <>
                                                {
                                                    groupData?.length > 0 && groupData?.map((group) => (
                                                        group?.labs?.length > 0 &&
                                                        <ListItem>
                                                            <Link to={{
                                                                    pathname : `/alllabs/${group?.groupId}`,
                                                                    state : {
                                                                        labGroup : group?.groupName
                                                                    },
                                                                }}
                                                            >
                                                                <ListItemText 
                                                                    primary={group?.groupName} 
                                                                    className="Link_item"
                                                                />
                                                            </Link>
                                                        </ListItem>
                                                    ))
                                                }
                                            </>
                                        }
                                    </Collapse> */}
                </>
                {/* Lab::End */}
                {/* category::Start */}
                {/* {Cookies.get("userRole") === "TenantAdmin" &&
                                    <>
                                        <ListItem className='sidebar-list-items'
                                            onClick={() => open && 
                                                this.setState({
                                                    dropDown : false,
                                                    dropDown_two : false,
                                                    dropDown_three : !dropDown_three,
                                                    dropDown_four : false,
                                                    dropDown_five : false,
                                                    dropDown_six : false,
                                                    dropDown_seven : false,
                                                    dropDown_eight : false,
                                                    dropDown_nine : false,
                                                })
                                            }
                                        >
                                            <ListItemIcon>
                                                <CategoryIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Category"/>
                                            {dropDown_three ? 
                                                <ExpandMoreIcon />
                                                : 
                                                <ChevronRightIcon />
                                            }
                                        </ListItem>
                                        <Collapse in={dropDown_three} timeout="auto" unmountOnExit>
                                            <ListItem >
                                                <ListItemIcon>
                                                   
                                                </ListItemIcon>
                                                <Link to="/createcategory">
                                                    <ListItemText primary="Create Category" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                            <ListItem >
                                                <ListItemIcon>
                                                   
                                                </ListItemIcon>
                                                <Link to="/category/AllCategory">
                                                    <ListItemText primary="All Categories" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                        </Collapse>
                                    </>
                                } */}
                {/* category::End */}
                {/* expertise-level::Start */}
                {/* {Cookies.get("userRole") === "TenantAdmin" &&
                                    <>
                                        <ListItem  className='sidebar-list-items'
                                            onClick={() => open && 
                                                this.setState({
                                                    dropDown : false,
                                                    dropDown_two : false,
                                                    dropDown_three : false,
                                                    dropDown_four : !dropDown_four,
                                                    dropDown_five : false,
                                                    dropDown_six : false,
                                                    dropDown_seven : false,
                                                    dropDown_eight : false,
                                                    dropDown_nine : false,
                                                }
                                            )}
                                        >
                                            <ListItemIcon>
                                                <BarChartIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Expertise Level" />
                                            {dropDown_four ? 
                                                <ExpandMoreIcon/> 
                                                :
                                                <ChevronRightIcon/> 
                                            }
                                        </ListItem>
                                        <Collapse in={ dropDown_four } timeout="auto" unmountOnExit>
                                            <ListItem >
                                                <ListItemIcon>
                                                   
                                                </ListItemIcon>
                                                <Link to="/level/CreateLevels">
                                                    <ListItemText primary="Create Level" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                            <ListItem >
                                                <ListItemIcon>
                                                   
                                                </ListItemIcon>
                                                <Link to="/level/AllLevels">
                                                    <ListItemText primary="All Levels" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                        </Collapse>
                                    </>
                                }                       */}
                {/* expertise-level::End */}
                {/* labGroup-section : Start */}
                {/* {Cookies.get("userRole") === "TenantAdmin" &&
                                    <>
                                        <ListItem  className='sidebar-list-items'
                                            onClick={() => open && 
                                                this.setState({
                                                    dropDown : false,
                                                    dropDown_two : false,
                                                    dropDown_three : false,
                                                    dropDown_four : false,
                                                    dropDown_five : !dropDown_five,
                                                    dropDown_six : false,
                                                    dropDown_seven : false,
                                                    dropDown_eight : false,
                                                    dropDown_nine : false,
                                                }
                                            )}
                                        >
                                            <ListItemIcon>
                                                <GroupIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Lab Groups" />
                                            {dropDown_five ? 
                                                <ExpandMoreIcon/> 
                                                :
                                                <ChevronRightIcon/> 
                                            }
                                        </ListItem>
                                        <Collapse in={dropDown_five} timeout="auto" unmountOnExit>
                                            <ListItem >
                                                <ListItemIcon>
                                                  
                                                </ListItemIcon>
                                                <Link to="/creategroup">
                                                    <ListItemText primary="Create Group" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                            <ListItem >
                                                <ListItemIcon>
                                                   
                                                </ListItemIcon>
                                                <Link to="/allGroups">
                                                    <ListItemText primary="All Groups" className="Link_item" />
                                                </Link>
                                            </ListItem>
                                        </Collapse>
                                    </>
                                } */}
                {Cookies.get("userRole") === "TenantAdmin" && (
                  <>
                    <ListItem
                      className="policy-spacing sidebar-list-items"
                      onClick={() =>
                        open &&
                        this.setState({
                          dropDown: false,
                          dropDown_two: false,
                          dropDown_three: false,
                          dropDown_four: false,
                          dropDown_five: false,
                          dropDown_six: !dropDown_six,
                          dropDown_seven: false,
                          dropDown_eight: false,
                          dropDown_nine: false,
                          dropDown_ten: false,
                          dropDown_eleven: false,
                          dropDown_twelve: false,
                        })
                      }
                    >
                      <ListItemIcon>
                        <PolicyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Policy Management"
                        style={{
                          whiteSpace: "break-spaces",
                          padding: "0px 16px !important",
                        }}
                      />
                      {dropDown_six ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                    </ListItem>
                    <Collapse in={dropDown_six} timeout="auto" unmountOnExit>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/createpolicy">
                          <ListItemText
                            primary="Create Policy"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/allPolicies">
                          <ListItemText
                            primary="All Policies"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/attachPolicy">
                          <ListItemText
                            primary="Attach Policy"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/allAttachedPolicies">
                          <ListItemText
                            primary="All Attached Policies"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                    </Collapse>
                  </>
                )}
                {Cookies.get("userRole") === "TenantAdmin" && (
                  <>
                    <ListItem
                      className="policy-spacing sidebar-list-items"
                      onClick={() =>
                        open &&
                        this.setState({
                          dropDown: false,
                          dropDown_two: false,
                          dropDown_three: false,
                          dropDown_four: false,
                          dropDown_five: false,
                          dropDown_six: false,
                          dropDown_seven: !dropDown_seven,
                          dropDown_eight: false,
                          dropDown_nine: false,
                          dropDown_ten: false,
                          dropDown_eleven: false,
                          dropDown_twelve: false,
                        })
                      }
                    >
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="User Groups"
                        style={{
                          whiteSpace: "break-spaces",
                          padding: "0px 16px !important",
                        }}
                      />
                      {dropDown_seven ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </ListItem>
                    <Collapse in={dropDown_seven} timeout="auto" unmountOnExit>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/createUserGroup">
                          <ListItemText
                            primary="Create User Group"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          {/* 
                                                    <StarBorder />
                                                    */}
                        </ListItemIcon>
                        <Link to="/allUserGroups">
                          <ListItemText
                            primary="All User Groups"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                    </Collapse>
                  </>
                )}
                {Cookies.get("userRole") === "TenantAdmin" &&
                  Cookies.get("isTenantLogin") === "false" && (
                    <>
                      <ListItem
                        className="policy-spacing sidebar-list-items"
                        onClick={() =>
                          open &&
                          this.setState({
                            dropDown: false,
                            dropDown_two: false,
                            dropDown_three: false,
                            dropDown_four: false,
                            dropDown_five: false,
                            dropDown_six: false,
                            dropDown_seven: false,
                            dropDown_eight: !dropDown_eight,
                            dropDown_nine: false,
                            dropDown_ten: false,
                            dropDown_eleven: false,
                            dropDown_twelve: false,
                          })
                        }
                      >
                        <ListItemIcon>
                          {/* <GroupIcon/> */}
                          <CreditScoreIcon />
                        </ListItemIcon>

                        <ListItemText
                          primary="Credits & Transactions"
                          style={{
                            whiteSpace: "break-spaces",
                            padding: "0px 16px !important",
                          }}
                        />
                        {dropDown_eight ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </ListItem>
                      <Collapse
                        in={dropDown_eight}
                        timeout="auto"
                        unmountOnExit
                      >
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                    <StarBorder />
                                                    */}
                          </ListItemIcon>
                          <Link to="/Transactions">
                            <ListItemText
                              primary="Transactions"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                    <StarBorder />
                                                    */}
                          </ListItemIcon>
                          <Link to="/generateCoupon">
                            <ListItemText
                              primary="Generate Coupon"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                    <StarBorder />
                                                    */}
                          </ListItemIcon>
                          <Link to="/couponList">
                            <ListItemText
                              primary="Coupon List"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                      </Collapse>
                    </>
                  )}

                {((Cookies.get("userRole") === "TenantUser" &&
                  Cookies.get("isTenantLogin") === "false") ||
                  (Cookies.get("userRole") === "TenantAdmin" &&
                    Cookies.get("isTenantLogin") === "true")) && (
                    <>
                      <ListItem
                        className="policy-spacing sidebar-list-items"
                        onClick={() =>
                          open &&
                          this.setState({
                            dropDown: false,
                            dropDown_two: false,
                            dropDown_three: false,
                            dropDown_four: false,
                            dropDown_five: false,
                            dropDown_six: false,
                            dropDown_seven: false,
                            dropDown_eight: !dropDown_eight,
                            dropDown_nine: false,
                            dropDown_ten: false,
                            dropDown_eleven: false,
                            dropDown_twelve: false,
                          })
                        }
                      >
                        <ListItemIcon>
                          {/* <GroupIcon/> */}
                          <CreditScoreIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Credits and Transactions"
                          style={{
                            whiteSpace: "break-spaces",
                            padding: "0px 16px !important",
                          }}
                        />
                        {dropDown_eight ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </ListItem>
                      <Collapse in={dropDown_eight} timeout="auto" unmountOnExit>
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                    <StarBorder />
                                                    */}
                          </ListItemIcon>
                          <Link to="/myCredit">
                            <ListItemText
                              primary="Credits"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                    <StarBorder />
                                                    */}
                          </ListItemIcon>
                          <Link to="/Transactions">
                            <ListItemText
                              primary="Transactions"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                      </Collapse>
                    </>
                  )}
                <>
                  <ListItem
                    className="policy-spacing sidebar-list-items"
                    onClick={() =>
                      open &&
                      this.setState({
                        dropDown: false,
                        dropDown_two: false,
                        dropDown_three: false,
                        dropDown_four: false,
                        dropDown_five: false,
                        dropDown_six: false,
                        dropDown_seven: false,
                        dropDown_eight: false,
                        dropDown_nine: !dropDown_nine,
                        dropDown_ten: false,
                        dropDown_eleven: false,
                        dropDown_twelve: false,
                      })
                    }
                  >
                    <ListItemIcon>
                      {/* <GroupIcon/> */}
                      <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Lab Provision"
                      style={{
                        whiteSpace: "break-spaces",
                        padding: "0px 16px !important",
                      }}
                    />
                    {dropDown_nine ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItem>
                  <Collapse in={dropDown_nine} timeout="auto" unmountOnExit>
                    <ListItem>
                      <ListItemIcon>
                        {/* 
                                                <StarBorder />
                                                */}
                      </ListItemIcon>
                      <Link to="/labProvisionHistory">
                        <ListItemText
                          primary="Lab Provision History"
                          className="Link_item"
                        />
                      </Link>
                    </ListItem>
                  </Collapse>
                </>
                {/* labGroup-section : End */}
                {Cookies.get("userRole") === "TenantAdmin" &&
                  Cookies.get("isTenantLogin") === "true" && (
                    <>
                      <ListItem
                        className="policy-spacing sidebar-list-items"
                        onClick={() =>
                          open &&
                          this.setState({
                            dropDown: false,
                            dropDown_two: false,
                            dropDown_three: false,
                            dropDown_four: false,
                            dropDown_five: false,
                            dropDown_six: false,
                            dropDown_seven: false,
                            dropDown_eight: false,
                            dropDown_nine: false,
                            dropDown_ten: !dropDown_ten,
                            dropDown_eleven: false,
                            dropDown_twelve: false,
                          })
                        }
                      >
                        <ListItemIcon>
                          <LaptopWindowsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Monitoring"
                          style={{
                            whiteSpace: "break-spaces",
                            padding: "0px 16px !important",
                          }}
                        />
                        {dropDown_ten ? (
                          <ExpandMoreIcon />
                        ) : (
                          <ChevronRightIcon />
                        )}
                      </ListItem>
                      <Collapse in={dropDown_ten} timeout="auto" unmountOnExit>
                        <ListItem>
                          <ListItemIcon>
                            {/* 
                                                <StarBorder />
                                                */}
                          </ListItemIcon>
                          <Link to="/monitoring/live-user-list">
                            <ListItemText
                              primary="Live User List"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                      </Collapse>
                    </>
                  )}

                {/* Lab Access Start */}
                <>
                  <ListItem
                    className="policy-spacing sidebar-list-items"
                    onClick={() =>
                      open &&
                      this.setState({
                        dropDown: false,
                        dropDown_two: false,
                        dropDown_three: false,
                        dropDown_four: false,
                        dropDown_five: false,
                        dropDown_six: false,
                        dropDown_seven: false,
                        dropDown_eight: false,
                        dropDown_nine: false,
                        dropDown_ten: false,
                        dropDown_eleven: !dropDown_eleven,
                        dropDown_twelve: false,
                      })
                    }
                  >
                    <ListItemIcon>
                      <BadgeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Lab Access"
                      style={{
                        whiteSpace: "break-spaces",
                        padding: "0px 16px !important",
                      }}
                    />
                    {dropDown_eleven ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronRightIcon />
                    )}
                  </ListItem>
                  {Cookies.get("userRole") === "TenantUser" ? (
                    <Collapse in={dropDown_eleven} timeout="auto" unmountOnExit>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <Link to="/myGroups">
                          <ListItemText
                            primary="My Groups"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        </ListItemIcon>
                        <Link to="/myAttachedPolicies">
                          <ListItemText
                            primary="My Attached Policies"
                            className="Link_item"
                          />
                        </Link>
                      </ListItem>
                    </Collapse>
                  )
                    : (
                      <Collapse in={dropDown_eleven} timeout="auto" unmountOnExit>
                        <ListItem>
                          <ListItemIcon>
                          </ListItemIcon>
                          <Link to="/userGroups">
                            <ListItemText
                              primary="User Groups"
                              className="Link_item"
                            />
                          </Link>
                        </ListItem>
                      </Collapse>
                    )}
                </>
                {/* Lab Access End*/}
                <>
                  <ListItem
                    className="policy-spacing sidebar-list-items"
                    onClick={() =>
                      open &&
                      this.setState({
                        dropDown: false,
                        dropDown_two: false,
                        dropDown_three: false,
                        dropDown_four: false,
                        dropDown_five: false,
                        dropDown_six: false,
                        dropDown_seven: false,
                        dropDown_eight: false,
                        dropDown_nine: false,
                        dropDown_ten: false,
                        dropDown_eleven: false,
                        dropDown_twelve: !dropDown_twelve,
                      })
                    }
                  >
                    <ListItemIcon>
                      {/* <GroupIcon/> */}
                      <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="Grading History"
                      style={{
                        whiteSpace: "break-spaces",
                        padding: "0px 16px !important",
                      }}
                    />
                    {dropDown_twelve ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </ListItem>
                  <Collapse in={dropDown_twelve} timeout="auto" unmountOnExit>
                    <ListItem>
                      <ListItemIcon>
                        {/* 
                                                <StarBorder />
                                                */}
                      </ListItemIcon>
                      <Link to="/gradingHistory">
                        <ListItemText
                          primary="History"
                          className="Link_item"
                        />
                      </Link>
                    </ListItem>
                  </Collapse>
                </>
              </Drawer>
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {this.props.children}
              </Box>
            </Box>
          </SidebarMain>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.authReducer,
});

export default withStyles({ withTheme: true })(
  withRouter(connect(mapStateToProps, { handleAuthTokenError })(NewSidebar))
);
