import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import "../../components/Muidatatable/style"

const optionstable = {
  print: false,
  download: false,
  viewColumns: false,
  filter: true,
  selectableRows: false,
  responsive: "standard",
};

export default class Datatable extends Component {
  constructor() {
    super();
    this.state = {
      page : 0,
      rowsPerPage : 7
    }
  }

  handleChangePage = (event, newPage) => {
      this.setState({
          page : newPage
      })
  };

  handleChangeRowsPerPage = (event) => {
      this.setState({
          rowsPerPage : parseInt(event.target.value, 7),
          page : 0
      })
  };

  render() {
    const {data,Table_value,options} = this.props

    return (
      <Grid container style={{width:"100%"}}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* <MainTabContainertable className="table_main"> */}
              <MUIDataTable
                data={data}
                columns={Table_value}
                options={{...optionstable,...options}}
              />
            {/* </MainTabContainertable> */}
          </Grid>
        </Grid>
    );
  }
}