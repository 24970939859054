export const url = "https://api.dev.cloudkida.com";

export const prodUrl = "https://api.cloudkida.com";

export const configuration = {
    googleSignIn : url + "/googleoauth/{proxy+}",
    signIn : url + "/sign_in",
    mfaVerification : url + "/sign_in/mfa_verification",
    enforcePasswordChange : url + "/enforce_pass_change",
    mfaSetup : url + "/setup_mfa",
    verifyMfaSetup : url + "/verify_mfa_setup",
    disableMFA : url + "/setup_mfa",  
    // URL is same as mfaSetup but request method is different
    confirmForgotPassword : url + "/user/confirm_forgot_password",
    resendTemporaryPassword: url + "/admin_resend_user_password",
    forgotPassword : url + "/user/forgot_password",
    signOut : url + "/user/sign_out",
    createTenantUser : url + "/create_tenantuser",
    adminCreateUser : url + "/admin_create_user",
    userList : url + "/user/all",
    createLab : url + "/lab_creation",
    getLabs : url + "/labs/short",
    labProvisioning : url + "/lab_provision",
    getSingleLab : url + "/lab_creation",
    getLabProvisionStatus : url + "/lab_provision",
    getInstancePassword : url + "/lab_password",
    updateLab : url + "/lab_creation",
    deleteLab : url + "/lab_creation",
    // URL is same as createLab but request method is different
    searchLabs : url + "/labs/search",
    getGroupBasedLabs : url + "/labs/group",
    uploadPicture : url + "/upload_picture",
    labDeprovision : url + "/user_lab_deprovision",
    getCategory : url + "/lab_category/all",
    createCategory : url + "/lab_category",
    getSingleCategory : url + "/lab_category",
    updateCategory : url + "/lab_category",
    deleteCategory : url + "/lab_category",
    // URL is same as getCategory but request method is different
    getAllLevel : url + "/lab_level/all",
    createLevel : url + "/lab_level",
    getSingleLevel : url + "/lab_level",
    updateLevel : url + "/lab_level",
    deleteLevel : url + "/lab_level",
    // URL is same as getSingleLevel but request method is different
    createGroup : url + "/lab_group",
    getSingleGroup : url + "/lab_group",
    updateGroup : url + "/lab_group",
    deleteGroup : url + "/lab_group",
    // URL is same as createGroup but request method is different
    getAllGroup : url + "/lab_group/all",
    createPolicy : url + "/lab_policy",
    getSinglePolicy : url + "/lab_policy",
    updatePolicy : url + "/lab_policy",
    deletePolicy : url + "/lab_policy",
    getAllPolicy : url + "/lab_policy/all",
    searchPolicy : url + "/lab_policy/search",
    // URL is same as createPolicy but request method is different
    getAllAttachedPolicy : url + "/lab_policy/assignment/all",
    attachPolicy : url + "/lab_policy/assignment",
    detachPolicy : url + "/lab_policy/assignment",
    editAttachedPolicy : url + "/lab_policy/assignment",
    getSingleAttachedPolicy : url + "/lab_policy/assignment",
    getAttachedPolicySearch : url + "/lab_policy/assignment/search",
    getMyAttachedPolicies : url + "/lab_policy/assignment/getuserpolicy",
    // URL is same as attachPolicy but request method is different
    getAllUserGroups : url + "/user_groups/all",
    createUserGroup : url + "/user_groups",
    updateUserGroup : url + "/user_groups",
    deleteUserGroup :  url + "/user_groups",
    getSingleUserGroup : url + "/user_groups",
    // URL is same as createUserGroup but request method is different
    getGroupUser : url + "/user_groups/user",
    deleteUserFromGroup : url + "/user_groups/user",
    addUserIntoGroup : url + "/user_groups/user",
    addMultipleUsersToGroup : url + "/user_groups/multiuser",
    // URL is same as getGroupUser but request method is different
    getUserProfileData : url + "/user",
    updateUserProfileData : url + "/user",
    // URL is same as getUserProfileData but request method is different
    disableAllUsers : url + "/user/disable/all",
    disableSpecificUsers : url + "/user/disable",
    enableAllUsers : url + "/user/enable/all",
    enableSpecificUsers : url + "/user/enable",
    createPaymentIntent : url + "/payments/create-intent",
    createCheckoutSession : url + "/payments/create-checkout-session",
    confirmPayment : url + "/payments/confirm-payment",
    getCredits : url + "/credit",
    getTenantCredits : url + "/tenantCredit",
    getTenantAdminTransactionHistory : url + "/transaction/tenant",
    getTenantUserTransactionHistory : url + "/transaction/tenant/user",
    getLabProvisionHistory : url + "/labprovision/history",
    getWhiteLable : url + "/tenant/label",
    getLiveUsers : url + "/dashboard/user",
    searchTenants : url +"/tenant/search",  
    // Api For lab Suspend and LAb Resume 
    labSuspend :url + "/instances/suspend",
    labResume :url + "/instances/resume",
    // Api for get tenant credit transaction => isTenantLogin = true and userrole = TenantAdmin
    getTenantTansactionHistory : url + "/tenantTransaction/all",
    getSupport:url + "/sns/support/pub",
    // giveRating:url +"/lab_rating",
    giveRating:url +"/lab-rating",
    getLabRatingHistory: url +"/lab_rating",
    getLabCount:url +"/labs/count",
    uploadBulkUsers:url +"/create_bulkuser",
    getSingleUser:url+"/users/get_single_user",
    demoExcelfile:"https://dev-cloudkida-data.s3.ap-south-1.amazonaws.com/10__fake__users.xlsx",
    //Lab Access 
    getMyGroups:url+"/groups_per_user",
    //RazorPay
    createOrder : url + '/payment/create-order',
    verifyPayment : url + '/payment/verify',
    gradeResults : url + '/graderesults',
    createGradingTask: url + '/gradetask',
    startGrading: url + '/grading-service',
    getGradingResults: url + '/grade-results',
}

// export const configuration = {
//     signIn : prodUrl + "/sign_in",
//     mfaVerification : prodUrl + "/sign_in/mfa_verification",
//     enforcePasswordChange : prodUrl + "/enforce_pass_change",
//     mfaSetup : prodUrl + "/setup_mfa",
//     verifyMfaSetup : prodUrl + "/verify_mfa_setup",
//     disableMFA : prodUrl + "/setup_mfa",  
//     // prodUrl is same as mfaSetup but request method is different
//     confirmForgotPassword : prodUrl + "/user/confirm_forgot_password",
//     forgotPassword : prodUrl + "/user/forgot_password",
//     signOut : prodUrl + "/user/sign_out",
//     createTenantUser : prodUrl + "/create_tenantuser",
//     adminCreateUser : prodUrl + "/admin_create_user",
//     userList : productionUrl + "/users",
//     createLab : prodUrl + "/lab_creation",
//     getLabs : prodUrl + "/labs",
//     labProvisioning : prodUrl + "/lab_provision",
//     getSingleLab : prodUrl + "/lab_creation",
//     getLabProvisionStatus : prodUrl + "/lab_provision",
//     getInstancePassword : prodUrl + "/lab_password",
//     updateLab : prodUrl + "/lab_creation",
//     // prodUrl is same as createLab but request method is different
//     uploadPicture : prodUrl + "/upload_picture",
//     labDeprovision : prodUrl + "/user_lab_deprovision",
//     getCategory : prodUrl + "/lab_category",
//     createCategory : prodUrl + "/lab_category",
//     getSingleCategory : prodUrl + "/lab_category",
//     updateCategory : prodUrl + "/lab_category",
//     // prodUrl is same as getCategory but request method is different
//     getAllLevel : prodUrl + "/lab_level/all",
//     createLevel : prodUrl + "/lab_level",
//     getSingleLevel : prodUrl + "/lab_level",
//     updateLevel : prodUrl + "/lab_level",
//     // prodUrl is same as getSingleLevel but request method is different
//     createGroup : prodUrl + "/lab_group",
//     getSingleGroup : prodUrl + "/lab_group",
//     updateGroup : prodUrl + "/lab_group",
//     // prodUrl is same as createGroup but request method is different
//     getAllGroup : prodUrl + "/lab_group/all",
//     uploadBulkUsers:prodUrl+"/create_bulkuser",
//     getSingleUser:prodUrl+"/users/get_single_user"
// }