import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Datatable from "../../components/Muidatatable/Datatable";
import { configuration } from "../../helper/appconfig";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import {
    CircularProgress,
    Typography,
    Grid,
    Autocomplete,
    Box,
    TextField,
    IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import { TableMain } from "../AllUsers/Style";
import { MainCard } from "../../common-styled";
import { MainTitle } from "../CreateUser/Style";
import { handleAuthTokenError } from "../../redux/actions/authActions";
import "../ViewLab/style.css";
import { TableRow, TableCell } from "@material-ui/core";
import VisibilityIcon from '@mui/icons-material/Visibility';

const GroupTableValue = (data) => [
    {
        name: "Index",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value) {
                    return (
                        <span
                            className="badge badge-primary"
                            style={{ backgroundColor: "transparent" }}
                        >
                            <div className="table_data table_data_index">
                                <p>{value}</p>
                            </div>
                        </span>
                    );
                }
            },
        },
    },
    {
        name: "Provision Id",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                if (value) {
                    return (
                        <span
                            className="badge badge-primary"
                            style={{ backgroundColor: "transparent" }}
                        >
                            <div className="table_data">
                                <p>{value}</p>
                            </div>
                        </span>
                    );
                }
            },
        },
    },
    {
        name: "Obtained Marks",
        options: {
            filter: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <span
                        className="badge badge-primary"
                        style={{ backgroundColor: "transparent" }}
                    >
                        <div className="table_data">
                            <p>{value}</p>
                        </div>
                    </span>
                );
            },
        },
    },
    {
        name: "Action",
        options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                    <div className="action_div">
                        <Link
                            to={{
                                pathname: `/viewGradingHistory/${value}`,
                                state: {
                                    provision_id: value
                                },
                            }}
                        >
                            <IconButton title="View">
                                <VisibilityIcon />
                            </IconButton>
                        </Link>
                    </div>
                );
            },
        },
    },
];

class GradingHistory extends Component {
    constructor() {
        super();
        this.state = {
            gradingHistoryArr: [],
            loading: false,
            selectedId: null,
            userData: [],
        };
    }

    componentDidMount() {
        this.getGradeHistory()
    }

    itemArray = () => {
        return [
            {
                name: "Grading History",
                active: false,
                link: "",
            },
            {
                name: "History",
                active: true,
                link: "",
            },
        ];
    };

    renderGradeHistoryData = () => {
        const { gradingHistoryArr } = this.state;

        if (!gradingHistoryArr?.length) return [];

        const excludedKeys = ['provisionid', 'userid', 'tenantid', 'timestamp'];
        let resultData = [];

        gradingHistoryArr.forEach((group, idx) => {
            const normalizedGroup = Object.fromEntries(
                Object.entries(group).map(([key, value]) => [key.toLowerCase(), value])
            );

            const groupKeys = Object.keys(normalizedGroup).filter(
                (key) => !excludedKeys.includes(key)
            );

            let totalMaximumMarks = 0;
            let totalObtainedMarks = 0;

            groupKeys.forEach((serverName) => {
                const serverData = normalizedGroup[serverName];
                if (serverData) {
                    Object.values(serverData).forEach((data) => {
                        totalMaximumMarks += parseInt(data.maximum_marks || 0, 10);
                        totalObtainedMarks += parseInt(data.obtained_maximum_marks || 0, 10);
                    });
                }
            });

            const provisionId = normalizedGroup.provisionid || "N/A";

            resultData.push([
                idx + 1,
                group?.provisionId,
                `${totalObtainedMarks && totalMaximumMarks ? (totalObtainedMarks + '/' + totalMaximumMarks) : '0/0'}`,
                provisionId,
            ]);
        });

        return resultData;
    };

    setID = (evt, val) => {
        if (val == null) {
            this.setState({
                selectedId: val,
                loading: false,
                groupData: [],
                attachedPolicies: []
            });
            return;
        }

        this.setState({ selectedId: val, loading: true }, () => {
            // this.getGradeHistory(val);
        });
    };


    getFilteredUsers = (evt) => {
        const url = configuration.userList;
        const tokenType = this.props?.authData?.tokenType;
        const idToken = this.props?.authData?.idToken;

        const headers = {
            "Authorization": `${tokenType} ${idToken}`
        }
        let body = {
            "limit": "60",
            "filter": evt.target.value
        }
        axios.post(url, body, { headers })
            .then((response) => {
                console.log(response, "responseFromGetAllUsers")
                this.setState({
                    userData: response.data?.Users
                })
            })
            .catch((error) => {
                console.log(error, "errorFromGetAllUsers")
                let errorMessage = error?.response?.data?.message;
                if (errorMessage === "Unauthorized") {
                    this?.props?.handleAuthTokenError(this.props)
                }
                if (!error.response) {
                    toastAlert("Please check your internet connection.", "error")
                } else {
                    // toastAlert("Something went wrong. Please try again later.")
                    toastAlert(errorMessage ? errorMessage : "Something went wrong. Please try again later.", "error")
                }
                // toastAlert(errorMessage ? errorMessage : "Something went wrong. Please try again later.","error")
            });
    }

    getGradeHistory = (data) => {
        this.setState({ loading: true })
        const url = configuration.gradeResults;
        const tokenType = this.props?.authData?.tokenType;
        const idToken = this.props?.authData?.idToken;
        const headers = {
            Authorization: `${tokenType} ${idToken}`,
        };

        axios
            .get(url, { headers })
            .then((response) => {
                this.setState({
                    gradingHistoryArr: response?.data?.grades,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error, "errorFromGetMyGroups");
                let errorMessage = error?.response?.data?.message;
                if (errorMessage === "Unauthorized") {
                    this?.props?.handleAuthTokenError(this.props);
                }
                this.setState({
                    loading: false,
                });
                if (!error.response) {
                    toastAlert("Please check your internet connection.", "error");
                } else {
                    toastAlert(
                        errorMessage
                            ? errorMessage
                            : "Something went wrong. Please try again later.",
                        "error"
                    );
                }
            });
    };

    getAttachedPolicies = (data) => {
        const url = configuration.getSingleAttachedPolicy;
        const tokenType = this.props?.authData?.tokenType;
        const idToken = this.props?.authData?.idToken;

        const headers = {
            Authorization: `${tokenType} ${idToken}`,
        };
        const params = {
            Id: data?.userId
        }
        axios
            .get(url, { headers, params })
            .then((response) => {
                this.setState({
                    attachedPolicies: response?.data.attachedPolicies,
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error, "errorFromGetMyGroups");
                let errorMessage = error?.response?.data?.message;
                if (errorMessage === "Unauthorized") {
                    this?.props?.handleAuthTokenError(this.props);
                }
                this.setState({
                    loading: false,
                });
                if (!error.response) {
                    toastAlert("Please check your internet connection.", "error");
                } else {
                    toastAlert(
                        errorMessage
                            ? errorMessage
                            : "Something went wrong. Please try again later.",
                        "error"
                    );
                }
            });
    };

    render() {
        const {
            loading,
            gradingHistoryArr,
            selectedId,
            userData
        } = this?.state;

        const gradingHistoryOptions = {
            customRowRender: (data, rowIndex) => {
                const provision_id = gradingHistoryArr[rowIndex]?.provisionId;

                return (
                    <TableRow
                        component={Link}
                        to={{
                            pathname: `/viewGradingHistory/${provision_id}`,
                            state: { provision_id },
                        }}
                        key={rowIndex}
                        hover
                    >
                        {data.map((cell, index) => (
                            <TableCell key={index}>{cell}</TableCell>
                        ))}
                    </TableRow>
                );
            },
        };

        return (
            <div>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Breadcrumb items={this?.itemArray()} />

                    {/* <Grid item lg={6} sm={12} xs={12} md={6}>
                        <Autocomplete
                            name="selectedId"
                            className='create-policy-autocomplete'
                            value={selectedId}
                            id="tags-outlined"
                            options={userData}
                            size="small"
                            onChange={(event, value) => this.setID(event, value)}
                            getOptionLabel={(option) => option?.Email}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={"Search User"}
                                    placeholder={"Search User"}
                                    onChange={(e) => this.getFilteredUsers(e)}
                                />
                            )}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ '& > img': { flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option.Email} (User)
                                </Box>
                            )}
                        />
                    </Grid> */}
                </Box>

                {/* Group Data Section */}
                {gradingHistoryArr && gradingHistoryArr.length !== 0 ? (
                    <MainCard>
                        <MainTitle>
                            <Typography className="card_title">Grading History</Typography>
                        </MainTitle>
                        <TableMain className="muiMainTable">
                            {loading ? (
                                <Grid mt={5} container style={{ justifyContent: "center" }}>
                                    <CircularProgress style={{ color: "var(--secondary-dark)" }} size={50} />
                                </Grid>
                            ) : (
                                <Datatable
                                    data={this.renderGradeHistoryData()}
                                    Table_value={GroupTableValue(gradingHistoryArr)}
                                    options={gradingHistoryOptions}
                                />
                            )}
                        </TableMain>
                    </MainCard>
                ) : null}


                {/* No Data Message */}
                {(!gradingHistoryArr || gradingHistoryArr.length === 0) && !loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '70vh',
                            height: 'auto',
                            padding: 2,
                        }}
                    >
                        <Typography variant="h6">No Data Found</Typography>
                    </Box>
                )}
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.authReducer,
});

export default connect(mapStateToProps, { handleAuthTokenError })(GradingHistory);
