import styled from 'styled-components'

export const TableMain = styled.div`
.MuiPaper-elevation4
{
box-shadow:none!important;
}
.MuiCard-root
{
    box-shadow:none!important;   
}
.action_div button button {
    padding: 3px 5px;
}
.action_div button {
    padding: 10px;
}
`
export const MainTabContainertable = styled.div`
.table_main .MuiPaper-root
{
  width: 100vw !important;
  /* width: calc(100vw - 10vw); */
}
`