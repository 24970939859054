import {
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    Button,
    Modal,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import axios from "axios";
import React, { Component } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { configuration } from "../../helper/appconfig";
import toastAlert from "../../helper/ToastAlert/ToastAlert";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { handleAuthTokenError } from "../../redux/actions/authActions";
import "../AllLabs/style.css";
import "../ViewLab/style.css";
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
export class ViewResult extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            showGetGrading: false,
            gradingResultArr: {},
            activeTab: ""
        };
    }

    componentDidMount = () => {
        this.getGradingResults();
    }

    itemArray = () => {
        return [
            {
                name: "Grading History",
                active: false,
                link: "/gradingHistory",
            },
            {
                name: "View",
                active: true,
                link: "",
            },
        ];
    };

    getGradingResults = () => {
        const url = `${configuration.getGradingResults}/${this.props?.location?.state?.provision_id}`;
        const tokenType = this.props?.authData?.tokenType;
        const idToken = this.props?.authData?.idToken;
        const headers = {
            "Authorization": `${tokenType} ${idToken}`
        }
        this.setState({
            loading: true
        })

        return axios.get(url, { headers })
            .then((response) => {
                this.setState({
                    loading: false,
                    gradingResultArr: response?.data?.grades?.result,
                    activeTab: Object.keys(response?.data?.grades?.result)[0]
                })
                if (!Object.keys(response?.data?.grades?.result).length) {
                    toastAlert("No Result Found", "error")
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
                let errorMessage = error?.response?.data?.message;
                if (errorMessage === "Unauthorized") {
                    this?.props?.handleAuthTokenError(this.props)
                }
                if (!error.response) {
                    toastAlert("Please check your internet connection.", "error")
                }
                else {
                    toastAlert(errorMessage ? errorMessage : "Something went wrong", "error")
                }
                // toastAlert(errorMessage ? errorMessage : "Something went wrong","error")
            });
    }

    handleTabClick = (serverName) => {
        this.setState({
            activeTab: serverName
        });
    }

    render() {
        const {
            loading,
            gradingResultArr,
            activeTab,
        } = this.state;
        const { match, authData } = this.props;

        return (
            <div>
                {loading ? (
                    <Grid container style={{ justifyContent: "center" }}>
                        <CircularProgress
                            style={{
                                color: "var(--secondary-dark)",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            size={50}
                        />
                    </Grid>
                ) : (
                    <>
                        <Breadcrumb items={this?.itemArray()} />

                        <div>
                            {gradingResultArr && Object.keys(gradingResultArr).length > 0 ? (
                                <div style={{
                                    marginTop: "20px",
                                    padding: "10px",
                                    fontFamily: "Arial, sans-serif",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}>
                                    {gradingResultArr && Object.keys(gradingResultArr).length > 0 && (
                                        <>
                                            {(() => {
                                                const grandTotals = Object.values(gradingResultArr).reduce(
                                                    (totals, serverDetails) => {
                                                        const serverMaximumMarks = parseInt(serverDetails.maximum_marks, 10) || 0;
                                                        const serverObtainedMaximumMarks = parseInt(serverDetails.obtained_maximum_marks, 10) || 0;

                                                        totals.grandTotalMaximumMarks += serverMaximumMarks;
                                                        totals.grandTotalObtainedMarks += serverObtainedMaximumMarks;
                                                        return totals;
                                                    },
                                                    { grandTotalObtainedMarks: 0, grandTotalMaximumMarks: 0 }
                                                );

                                                return Object.entries(gradingResultArr).map(([serverName, serverDetails], index, array) => {
                                                    const totalObtainedMarks = serverDetails.tasks.reduce(
                                                        (acc, task) => acc + parseInt(task.obtained_marks, 10), 0);
                                                    const totalMaximumMarks = serverDetails.tasks.reduce(
                                                        (acc, task) => acc + parseInt(task.maximum_marks, 10), 0);
                                                    const obtainedMaximumMarks = serverDetails?.obtained_maximum_marks;
                                                    const maximumMarks = serverDetails?.maximum_marks;

                                                    return (
                                                        <div key={serverName} style={{ marginBottom: "20px", padding: "15px" }}>
                                                            <h3 style={{ fontSize: "1.2rem", color: "#333", marginBottom: "10px" }}>
                                                                {serverName}
                                                            </h3>

                                                            <p style={{ fontSize: "1rem", marginBottom: "10px" }}>
                                                                <strong>Obtained Marks / {serverName} Marks:</strong> {obtainedMaximumMarks} / {maximumMarks}
                                                                {/* <strong>Obtained Marks / {serverName} Marks:</strong> {totalObtainedMarks} / {totalMaximumMarks} */}
                                                            </p>

                                                            <div>
                                                                {serverDetails.tasks.map((test, testIndex) => (
                                                                    <div
                                                                        key={testIndex}
                                                                        style={{
                                                                            padding: "10px",
                                                                            marginBottom: "10px",
                                                                            borderBottom: "1px solid #ddd",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "space-between",
                                                                                alignItems: "center",
                                                                                marginBottom: "5px",
                                                                            }}
                                                                        >
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                {test.no}. {test.name}
                                                                            </span>
                                                                            <span style={{ fontWeight: "bold" }}>
                                                                                {test.obtained_marks} / {test.maximum_marks}
                                                                            </span>
                                                                        </div>

                                                                        <div>
                                                                            <span style={{ marginLeft: "15px" }}>
                                                                                {test.message}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            {index === array.length - 1 && (
                                                                <div style={{ marginTop: "20px" }}>
                                                                    <span style={{
                                                                        marginLeft: "15px",
                                                                        fontWeight: "bold",
                                                                        fontSize: "1.5rem",
                                                                        color: "#333",
                                                                    }}>
                                                                        Total Marks for All Servers: {grandTotals.grandTotalObtainedMarks} / {grandTotals.grandTotalMaximumMarks}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                });
                                            })()}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh", fontFamily: "Arial, sans-serif", fontSize: "1.5rem", color: "#888" }}>
                                    No Data Found
                                </div>
                            )}
                        </div>

                    </>
                )}

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.authReducer,
});

export default connect(mapStateToProps, { handleAuthTokenError })(ViewResult);
