import { Breadcrumbs, Grid, Typography } from '@mui/material';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Breadcrumb extends Component {
  render() {
    const { items } = this?.props
    return (
        <div style={{ width: "100%" }}>
            <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className="card-main breadcrumb-card">
                        <Breadcrumbs 
                            aria-label="breadcrumb" 
                            className='breadcrum-main'
                        >
                            {items?.length && items?.map((item, i) => (
                                (!item.link.trim()) ?
                                    <Typography 
                                        className={'breadcrumb ' + (item?.active && "active")}
                                    >
                                        {item.name}
                                    </Typography>
                                :
                                    <Link 
                                         to={{
                                            pathname : item.link,
                                            state : item.state
                                        }} 
                                        className={'breadcrumb ' + (item?.active && "active")}
                                    >
                                        {item.name}
                                    </Link>
                            ))}
                        </Breadcrumbs>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
  }
}
export default Breadcrumb;