import { Button } from '@mui/material'
import styled from 'styled-components'

export const AuthContent = styled.div`
	margin-left: ${(props) => (props.collapsed ? '18%' : '1%')};
	transition: all 0.2s;
	/* margin-top:px; */
`
export const MainTitle = styled.div`
display: flex;
justify-content: space-between;
`