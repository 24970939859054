import styled from 'styled-components'
export const MainTabContainertable = styled.div`
// .table_main .MuiPaper-root
// {
//   width: 100vw !important; 
// }
/* .MuiTypography-colorInherit
{
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
        font-family: "Montserrat-Regular" !important;
        color:var(--gray-text) !important;
        font-weight: 400 !important;
} */
.MuiTypography-body2 {
    color: inherit;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
    font-family: "Montserrat-Regular" !important;
    color: var(--gray-text) !important;
    font-weight: 400 !important;
}
.MuiTablePagination-select {
    text-align-last: right;
    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300))) !important;
    font-family: "Montserrat-Regular"!important;
    color: #000000;
    padding: 10px;
    border-radius: 5px;
    font-weight: 400;
}
.MuiSelect-selectMenu {
    min-height:0px;
    background-color: var(--black);
    color: white;
    padding:10px 15px!important;
    border-radius: 5px !important;
}
.MuiSelect-select:focus {
    border-radius: 0;
    /* background-color: rgba(0, 0, 0, 0.05); */
    background-color: var(--active);
}
.MuiTablePagination-selectIcon
{
  color: white !important;
  padding-left:7px;
}
`